// Lens Module

import Vue from 'vue'
import LensAPI from '@/api/lens'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    columnDrawerView: false,
    presets: [],

    isCustomPreset: false,

    // Pagination
    pagination: {
      currentPage: 1,
      limit: 10,
      totalItems: 0,
      totalPages: 0
    },
    /**
     * selectedPreset - source of truth, used to populate local state
     * appliedPreset - used for the current session modifications
     * localPreset - local preset for saving, apply changes to applied & selected
     */
    selectedPreset: {},
    appliedPreset: {},
    localPreset: {}
  },
  getters: {
    getPresets: state => state.presets,
    getSelectedPreset: state => state.selectedPreset,
    getAppliedPreset: state => state.appliedPreset,
    getLocalPreset: state => state.localPreset,
    getColumnDrawerView: state => state.columnDrawerView,
    getIsCustomPreset: state => state.isCustomPreset,

    getPagination: state => state.pagination
  },
  mutations: {
    SET_PRESETS (state, presets) {
      state.presets = presets
    },
    SET_SELECTED_PRESET (state, preset) {
      state.selectedPreset = { ...preset }
    },
    SET_APPLIED_PRESET (state, preset) {
      state.appliedPreset = { ...preset }
    },
    SET_LOCAL_PRESET (state, preset) {
      state.localPreset = { ...preset }
    },
    SET_PRESET_VALUE (state, { key, value }) {
      Vue.set(state.appliedPreset, key, value)
    },
    SET_LOCAL_PRESET_VALUE (state, { key, value }) {
      Vue.set(state.localPreset, key, value)
    },
    SET_COLUMN_DRAWER_VIEW (state, canShow) {
      state.columnDrawerView = canShow
    },
    SET_IS_CUSTOM_PRESET (state, isCustom) {
      console.log(`%c PRESET UPDATING from ${state.isCustomPreset} TO ${isCustom}`, 'font-size: 12px; color: orange')
      state.isCustomPreset = isCustom
    },
    SET_PAGINATION (state, pagination) {
      state.pagination = { ...pagination }
    }
  },
  actions: {
    async fetchPresets ({ state, commit }) {
      console.log('%c Fetching Presets', 'font-size: 14px; color: pink')
      const res = await LensAPI.LensPreset.getAll()
      const presets = res.data
      commit('SET_PRESETS', presets)
      const defaultPreset = presets.find(preset => preset.is_default)
      const presetToSelect = defaultPreset ?? presets?.[0]
      // If we dont currently have a selected preset, use default
      if (_.isEmpty(state.selectedPreset)) {
        commit('SET_SELECTED_PRESET', presetToSelect)
        commit('SET_LOCAL_PRESET', presetToSelect)
        commit('SET_APPLIED_PRESET', presetToSelect)
      }
    },
    /**
     * -- shouldSelect = true --
     * Used when a user changes presets for a report
     * OR
     * -- shouldSelect = false --
     * Used when a user is swapping between presets for editing
     */
    async setPresetById ({ state, commit }, { id, shouldSelect = false }) {
      const presets = state.presets

      const selectedPreset = (id ? presets.find((preset) => preset.id === id) : presets?.[0]) ?? {}
      // We should keep local and selected state seperate until save/update
      commit('SET_LOCAL_PRESET', selectedPreset)
      if (shouldSelect) {
        commit('SET_SELECTED_PRESET', selectedPreset)
        commit('SET_APPLIED_PRESET', selectedPreset)
      }
    },
    async updatePreset ({ dispatch, state, commit }, toUpdate) {
      const newPreset = {
        ...toUpdate
      }
      try {
        await LensAPI.LensPreset.update(toUpdate.id, newPreset)
      } catch (e) {
        console.error(e)
      }
      const originalPreset = state.localPreset
      // commit('SET_SELECTED_PRESET', { ...originalPreset, ...newPreset })
      // commit('SET_APPLIED_PRESET', { ...originalPreset, ...newPreset })
      commit('SET_LOCAL_PRESET', { ...originalPreset, ...newPreset })
      await dispatch('fetchPresets')
    },
    applyPreset ({ state, commit }, toUpdate) {
      commit('SET_APPLIED_PRESET', toUpdate)
      commit('SET_LOCAL_PRESET', toUpdate)
    },
    updatePresetField ({ commit }, { key, value }) {
      commit('SET_PRESET_VALUE', { key, value })
    },
    updateLocalPresetField ({ commit }, { key, value }) {
      commit('SET_LOCAL_PRESET_VALUE', { key, value })
    },
    updateIsCustomPreset ({ commit }, isCustom) {
      commit('SET_IS_CUSTOM_PRESET', isCustom)
    },
    updatePagination ({ commit }, pagination) {
      commit('SET_PAGINATION', pagination)
    }
  }
}
