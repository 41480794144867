import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'

const create = async (reportPayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/reports',
    reportPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const update = async (reportId, toUpdate) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/reports/${reportId}`,
    toUpdate,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const remove = async (reportId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.delete(
     `/reports/${reportId}`,
     {
       headers: {
         Authorization: `Bearer ${token}`
       }
     }
  )

  return data
}

const getByID = async (reportId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/reports/${reportId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

async function getAll (query) {
  const { Aa: token } = firebase.auth().currentUser

  const params = new URLSearchParams()

  if (query.name) params.append('name', query.name)
  if (query.lens_id) params.append('lens_id', query.lens_id)
  if (query.page) params.append('page', query.page)
  if (query.limit) params.append('limit', query.limit)

  const { data } = await AxiosConfig.get(`/reports/?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return data
}

export default {
  create,
  update,
  remove,
  getByID,
  getAll
}
