// Imports
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import store from '../store'

// FOR DEVELOPMENT
import ComponentView from '@/views/dev/ComponentView'

// Dynamic imports
const AccountView = () => import('@/views/account/AccountView')
const AdsPublicView = () => import('../views/ads/AdsPublicView')
const AdsPublicViewDiscover = () => import('../views/ads/AdsPublicViewDiscover')
const AuthCallbackView = () => import('../views/auth/AuthCallbackView')
const BoardsDetailsView = () => import('@/views/boards/BoardsDetailsView')
const BoardsPublicView = () => import('../views/boards/BoardsPublicView')
const BoardsView = () => import('@/views/boards/BoardsView')
const BrandView = () => import('@/views/brands/BrandView')
const BriefPublicViewV2 = () => import('../views/briefs/BriefPublicViewV2.vue')
const BriefsView = () => import('../views/briefs/BriefsView.vue')
const ChromeExtensionAuthView = () => import('../views/auth/ChromeExtensionAuthView')
const DashboardView = () => import('@/views/dashboard/DashboardView')
const DiscoveryAICategoryView = () => import('../views/discover/DiscoveryAICategoryView')
const DiscoveryBrandView = () => import('@/views/brands/DiscoveryBrandView')
const DiscoveryExpertPublicView = () => import('@/views/discover/DiscoveryExpertPublicView.vue')
const DiscoveryExpertView = () => import('@/views/discover/DiscoveryExpertView.vue')
const DiscoveryView = () => import('../views/discover/DiscoveryView')
const EditBriefView = () => import('../views/briefs/EditBriefView')
const ExpertRedirectView = () => import('../views/discover/ExpertRedirectView.vue')
const ForgotPasswordView = () => import('@/views/auth/ForgotPasswordView')
const LensView = () => import('@/views/lens/LensView')
const LensReportView = () => import('@/views/lens/LensReportView')
const LensTest = () => import('@/views/lens/LensTest')
const LibraryView = () => import('@/views/library/LibraryView')
const LoginEmailSentView = () => import('../views/auth/LoginEmailSentView')
const LoginView = () => import('@/views/auth/LoginView')
const PaymentViewV2 = () => import('@/views/account/PaymentViewV2')
const PerksView = () => import('@/views/account/PerksView')
const SignUpView = () => import('@/views/auth/SignUpView')
const SpyderBrandsView = () => import('../views/spyder/SpyderBrandsView.vue')
const SpyderView = () => import('@/views/spyder/SpyderView')
const ThankYouRedirectView = () => import('@/views/account/ThankYouRedirectView')
const TokenLoginView = () => import('../views/auth/TokenLoginView')

// Install Router
Vue.use(VueRouter)

// Install Meta
Vue.use(Meta)

// Main Routes
const routes = [
  {
    path: '/components',
    name: 'ComponentView',
    component: ComponentView
  },
  {
    path: '/lens',
    name: 'LensView',
    component: LensView,
    meta: { requiresAuth: true }
  },
  {
    path: '/lens/:lensId/report/:reportId',
    name: 'LensReport',
    component: LensReportView,
    meta: { requiresAuth: true }
  },
  {
    path: '/lens-test',
    name: 'LensTest',
    component: LensTest,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/sign-up',
    name: 'SignUpView',
    component: SignUpView
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView
  },
  {
    path: '/email-sent',
    name: 'LoginEmailSentView',
    component: LoginEmailSentView
  },
  {
    path: '/auth-callback',
    name: 'AuthCallbackView',
    component: AuthCallbackView
  },
  {
    path: '/share/boards/:id',
    name: 'BoardsPublicView',
    component: BoardsPublicView
  },
  {
    path: '/share/ads/:id',
    name: 'AdsPublicView',
    component: AdsPublicView
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/briefs',
    name: 'BriefsView',
    component: BriefsView,
    meta: { requiresAuth: true, theme: 'green' }
  },
  {
    path: '/edit-briefs/:id',
    name: 'EditBriefView',
    component: EditBriefView,
    meta: { requiresAuth: true, theme: 'green' }
  },
  {
    path: '/share/briefs/:id',
    name: 'BriefPublicView',
    component: BriefPublicViewV2,
    meta: { theme: 'green', hideSidebar: true }
  },
  {
    path: '/discovery',
    name: 'DiscoveryView',
    component: DiscoveryView,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/discovery-brands',
    name: 'DiscoveryBrandsView',
    component: DiscoveryView,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/discovery-experts',
    name: 'DiscoveryExpertsView',
    component: DiscoveryView,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/discovery-experts/:id',
    name: 'DiscoveryExpertView',
    component: DiscoveryExpertView,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/discovery/product-category/:category',
    name: 'DiscoveryProductCategoryView',
    component: DiscoveryAICategoryView,
    props: true,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/discovery/creative-targeting/:category',
    name: 'DiscoveryCreativeTargetingView',
    component: DiscoveryAICategoryView,
    props: true,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/share/discovery-experts/:id',
    name: 'DiscoveryExpertPublicView',
    component: DiscoveryExpertPublicView,
    meta: { theme: 'purple' }
  },
  {
    path: '/discovery-experts/:id/board/:boardId',
    name: 'DiscoveryExpertBoardPublicView',
    component: DiscoveryExpertView,
    meta: { theme: 'purple' }
  },
  {
    path: '/discovery-experts/:id/board/:boardId',
    name: 'DiscoveryExpertBoardView',
    component: DiscoveryExpertView,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/shared-discover/:id',
    name: 'SharedDiscover',
    component: AdsPublicViewDiscover
  },
  {
    path: '/library',
    name: 'LibraryView',
    component: LibraryView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/library-brands',
    name: 'LibraryBrandsView',
    component: LibraryView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/library-team',
    name: 'LibraryTeamView',
    component: LibraryView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/library-mobile-saving',
    name: 'LibraryMobileSavingView',
    component: LibraryView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/spyder',
    name: 'SpyderBrandsView',
    component: SpyderBrandsView,
    meta: { requiresAuth: true, theme: 'red' }
  },
  {
    path: '/library-spyder/:id/:view?',
    name: 'SpyderView',
    component: SpyderView,
    meta: { requiresAuth: true, theme: 'red' }
  },
  {
    path: '/boards',
    name: 'BoardsView',
    component: BoardsView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/boards/:id',
    name: 'BoardsDetailsView',
    component: BoardsDetailsView,
    props: true,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/discovery/brands/:id',
    name: 'DiscoveryBrandView',
    component: DiscoveryBrandView,
    props: true,
    meta: { requiresAuth: true, theme: 'purple' }
  },
  {
    path: '/brands/:id',
    name: 'BrandView',
    component: BrandView,
    props: true,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  // {
  //   path: '/account',
  //   name: 'AccountView',
  //   component: AccountView,
  //   meta: { requiresAuth: true, theme: 'blue' }
  // },
  {
    path: '/perks',
    name: 'PerksView',
    component: PerksView,
    meta: { requiresAuth: true, theme: 'blue' }
  },
  {
    path: '/manage-subscription',
    name: 'PaymentView',
    component: PaymentViewV2,
    meta: { requiresAuth: true, hideSidebar: true }
  },
  {
    path: '/thank-you-solo-monthly',
    name: 'MonthlySoloThankYouRedirectView',
    component: ThankYouRedirectView,
    meta: { requiresAuth: true, hideSidebar: true }
  },
  {
    path: '/thank-you-solo-annual',
    name: 'AnnualSoloThankYouRedirectView',
    component: ThankYouRedirectView,
    meta: { requiresAuth: true, hideSidebar: true }
  },
  {
    path: '/thank-you-team-monthly',
    name: 'MonthlyTeamThankYouRedirectView',
    component: ThankYouRedirectView,
    meta: { requiresAuth: true, hideSidebar: true }
  },
  {
    path: '/thank-you-team-annual',
    name: 'AnnualTeamThankYouRedirectView',
    component: ThankYouRedirectView,
    meta: { requiresAuth: true, hideSidebar: true }
  },
  {
    path: '/auth-extension',
    name: 'ChromeExtensionAuthView',
    component: ChromeExtensionAuthView,
    meta: { requiresAuth: true }
  },
  {
    path: '/token-login/:token',
    name: 'TokenLoginView',
    component: TokenLoginView
  },
  {
    path: '/:utm',
    name: 'ExpertRedirect',
    component: ExpertRedirectView,
    meta: { hideSidebar: true }
  },
  {
    path: '*',
    redirect: { name: 'DiscoveryView' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Route Guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['AuthModule/isUserLoggedIn']
  const isFreeTier = store.getters['AuthModule/isFreeTier']
  const team = store.getters['AuthModule/getTeam']
  const userFreeTrialDate = store.getters['AuthModule/getUserFreeTrialDate']
  const isInspoMode = store.getters['BriefsModule/getIsInspoMode']
  const userSubscription = store.getters['AuthModule/getUserSubscription']

  const needsUpgrade = isFreeTier && Date.now() > userFreeTrialDate + 6.048e8

  store.commit('RouterModule/SET_LAST_ROUTE', from.name)
  store.commit('RouterModule/SET_CURRENT_ROUTE', to.name)
  store.commit('RouterModule/SET_CURRENT_PARAMS', to.params)

  // Check the theme
  if (to.meta.theme) {
    if (isInspoMode) {
      store.commit('MiscModule/SET_THEME', 'green')
    } else {
      store.commit('MiscModule/SET_THEME', to.meta.theme)
    }
  }

  // store queries in cookies
  if (to.query.utm_source) {
    store.commit('MiscModule/SET_UTM_SOURCE', to.query.utm_source)
  }
  if (to.query.utm_medium) {
    store.commit('MiscModule/SET_UTM_MEDIUM', to.query.utm_medium)
  }
  if (to.query.utm_campaign) {
    store.commit('MiscModule/SET_UTM_CAMPAIGN', to.query.utm_campaign)
  }

  // Check if the user is logged in
  if (to.name !== 'LoginView' && !isAuthenticated && to.meta.requiresAuth) {
    next({ name: 'LoginView' })
  }

  // Check if user is on the correct plan to go to the briefs view
  if (to.name === 'BriefsView' && userSubscription?.product?.name !== 'Full Workflow' && userSubscription?.product?.name) {
    next({ name: 'LibraryView' })
  }

  // Check if someone needs to upgrade
  if (!['ThankYouRedirectView', 'PaymentView', 'LibraryView', 'DiscoveryView'].includes(to.name) && isAuthenticated && to.meta.requiresAuth && needsUpgrade && !team) {
    next({ name: 'PaymentView' })
  } else if (['LoginView', 'LoginEmailSentView'].includes(to.name) && isAuthenticated) {
    next({ name: 'DashboardView' })
  } else next()
})

export default router
