import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'

const create = async (lensPayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/lenses',
    lensPayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const getAll = async (query) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser
  const params = new URLSearchParams()

  if (query?.page) params.append('page', query.page)
  if (query?.limit) params.append('limit', query.limit)

  const { data } = await AxiosConfig.get(
    `/lenses?${params.toString()}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const get = async (id) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    `/lenses/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const update = async (id, updatePayload) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.patch(
    `/lenses/${id}`,
    updatePayload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const createTWAccount = async () => {
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.post(
    '/tw',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getTWAccounts = async () => {
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/tw',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getTWSignInURL = async () => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/connections/tw/iframe',
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}

const getTWRedirect = async (internalTwAccountId) => {
  // Pull the current token off the user
  const { Aa: token } = firebase.auth().currentUser

  const { data } = await AxiosConfig.get(
    '/tw/redirect?internal_tw_account_id=' + internalTwAccountId,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )

  return data
}
export default {
  create,
  createTWAccount,
  get,
  getAll,
  getTWAccounts,
  getTWRedirect,
  getTWSignInURL,
  update
}
